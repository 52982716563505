import React from "react";
import { useSelector, useDispatch } from "react-redux";
import TabNavItem from "../../Tools/TabNavItem";
import { pricingFun } from "../../../Reducer/pricing/pricingSlice";
import Subscription from "./subscription";
import BoostrapModel from "../../Tools/BoostrapModel";
import EditForm from "./editForm";
import AddForm from "./addForm";

const Pricing = () => {
  const [show, setShow] = React.useState(false);
  const [addShow, setAddShow] = React.useState(false);
  const tradeUsers = useSelector(state => state.pricing?.trade_users);
  const indiviualUsers = useSelector(state => state.pricing?.indiviual_users);
  const [active, setActive] = React.useState('');
  const [data, setData] = React.useState([]);
  const [idd, setIdd] = React.useState('');
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(pricingFun())
  }, [dispatch]);

  const onClick = (id) => {
    console.log("idd", id);
    setIdd(id);
    setShow(!show);
  }

  return (<div class="dashnboard pricing">
    <h3>{"Pricing controls"}<ul class="nav nav-tabs" id="myTab" role="tablist">
      <TabNavItem parentStyle={"nav-item"} childStyle={`nav-item ${!active && 'active'} ${active == "trade" && "active"}`} onClick={() => { setActive('trade'); setData(tradeUsers) }} title={"Trade Users"} />
      <TabNavItem parentStyle={"nav-item"} childStyle={`nav-item ${active == "Individual" && "active"}`} onClick={() => { setActive('Individual'); setData(indiviualUsers) }} title={"Individual Users"} />
    </ul>
      <a href="#" class="freeze_btn" onClick={() => setAddShow(!addShow)}>{"Add plans"}</a>&nbsp;<a href="#" class="freeze_btn">{"Freeze plans"}</a></h3>
    <Subscription data={data.length > 0 ? data : tradeUsers} onClick={onClick} />
    <BoostrapModel size={'lg'} show={show} component={<EditForm id={idd} onHide={() => setShow(!show)} />} onHide={() => setShow(!show)} heading={"Edit plan"} />
    <BoostrapModel size={'lg'} show={addShow} component={<AddForm onHide={() => setAddShow(!addShow)} />} onHide={() => setAddShow(!addShow)} heading={"Edit plan"} />
  </div>)
}
export default Pricing