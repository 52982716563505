import Button from "../Button";
const SubscriptionBox = ({parentSTyle, childStyle, title, time, pricing, benefits, onClick, id, style }) => {
    console.log("benefits", benefits)
    if (!Array.isArray(benefits)) {
        // Handle the case where data is not an array
        console.error("data is not an array");
        return null; // or some other error handling logic
      }
   return (<div class={parentSTyle}>
        <div class={childStyle}>
            <div class={`hd ${title == "Premium" && "pr"}`} style={style}>
                <h4>{title}</h4>
                <h5>{time}</h5>
            </div>
            <h4>{pricing}</h4>
            <ul>
                {benefits.length > 0 && benefits.map((item) => {
                    return <li>{item}</li>
                })}
            </ul>
            <Button style={'edit_plan'} title={"Edit Plan"} onClick={() => onClick(id)} />
        </div>
    </div>)
}

export default SubscriptionBox;