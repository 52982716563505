import React from "react"
import { Link } from "react-router-dom";
import BoostrapModel from "../../Tools/BoostrapModel"
import EditPageForm from "./editPageForm";

const SectionListBoxes = ({rows, parentStyle, childStyle}) => {
  const [show, setShow] = React.useState(false);

  return(<div class="row">
    {rows.length > 0 && rows.map((item)=>{
        return(<div class={parentStyle}>
        <div class={childStyle}>
          <h4>{item.title}</h4>
          <p>{item.description}</p>
          <BoostrapModel size={"lg"} show={show} component={<EditPageForm onHide={() => setShow(!show)} />} onHide={() => setShow(!show)} style={"warning Edit_Section"} heading={"Edit section"} />
          <Link onClick={()=>setShow(!show)}><img src="../assets/images/pencil-alt.png" alt="icon" /> {"Edit content"}</Link>
        </div>
      </div>)
    })}
</div>)
}
export default SectionListBoxes