export const transactionData = [
    { id: 1, email: "sahilpatel6@gmail.com", materialType: "Plastic", weight: "120 kg", price: "Rs 30/kg", totalAmount: "3600" },
    { id: 1, email: "test2@gmail.com", materialType: "Plastic", weight: "121 kg", price: "Rs 30/kg", totalAmount: "3600" },
    { id: 1, email: "test3@gmail.com", materialType: "Plastic", weight: "110 kg", price: "Rs 30/kg", totalAmount: "1500" },
    { id: 1, email: "seconemail@gmail.com", materialType: "Plastic", weight: "100 kg", price: "Rs 30/kg", totalAmount: "2000" },
    { id: 1, email: "helloworld@gmail.com", materialType: "Plastic", weight: "90 kg", price: "Rs 30/kg", totalAmount: "2500" }
];


export const headerWithDatePickerData = ["This week", "This month", "This year"];

export const sidebarItemMenu = [
    {
        active: 1,
        imageOne: 'assets/images/dash_img/view-grid.png',
        imageSecond: 'assets/images/dash_img/view-grid_C.png',
        title: "Dashboard",
        path: '/'
    },
    {
        active: 2,
        imageOne: 'assets/images/user.png',
        imageSecond: 'assets/images/dash_img/user_C.png',
        title: "User accounts",
        path: '/user_accounts'
    },
    {
        active: 3,
        imageOne: 'assets/images/dash_img/check-circle.png',
        imageSecond: 'assets/images/dash_img/check-circle_C.png',
        title: "Approval que",
        path: '/approval_que'
    },
    {
        active: 4,
        imageOne: 'assets/images/dash_img/mail.png',
        imageSecond: 'assets/images/dash_img/mail_C.png',
        title: "Send email",
        path: '/send_email'
    },
    {
        active: 5,
        imageOne: 'assets/images/dash_img/bell.png',
        imageSecond: 'assets/images/dash_img/bell_C.png',
        title: "Send notifications",
        path: '/send_notifications'
    },
    {
        active: 6,
        imageOne: 'assets/images/dash_img/check-circle.png',
        imageSecond: 'assets/images/dash_img/check-circle_C.png',
        title: "Pricing controls",
        path: '/pricing_controls'
    },
    {
        active: 7,
        imageOne: 'assets/images/dash_img/photograph.png',
        imageSecond: 'assets/images/dash_img/photograph_C.png',
        title: "Banner images",
        path: '/banner_images'
    },
    {
        active: 8,
        imageOne: 'assets/images/dash_img/document.png',
        imageSecond: 'assets/images/dash_img/document_C.png',
        title: "Pages",
        path: '/pages'
    },
    {
        active: 9,
        imageOne: 'assets/images/dash_img/flag.png',
        imageSecond: 'assets/images/dash_img/flag_C.png',
        title: "Block words",
        path: '/block_words'
    }
];

export const keyStatsPosignData = [
    {
        title: "155",
        description: "Basic 2 weeks ad"
    },
    {
        title: "145",
        description: "Basic 3 weeks ad"
    },
    {
        title: "155",
        description: "Basic 4 weeks ad"
    },
    {
        title: "135",
        description: "Basic 6 weeks ad"
    },
];

export const metricsData = [
    {
        title: "451",
        description: "Views on selling cars"
    },
    {
        title: "500",
        description: "Views on rental cars"
    },
    {
        title: "500",
        description: "Views on checking cars"
    },
    {
        title: "3200",
        description: "Views on website"
    },
    {
        title: "$25,0000",
        description: "Views on app"
    },
    {
        title: "500",
        desctiption: "Total views"
    }
];

export const overviewData = [
    {
        title: "500",
        description: "Total active ads"
    },
    {
        title: "500",
        description: "Total users"
    },
    {
        title: "500",
        description: "Business users"
    },
    {
        title: "500",
        description: "Individual users"
    },
    {
        title: "$25,0000",
        description: "Total payments received"
    },
    {
        title: "500",
        description: "Total Views"
    }
]
export const aprrovalUserColumn = [
    {
        label: "DATE",
        name: "registration_date"
    },
    {
        label: "USERNAME",
        name: "full_name"
    },
    {
        label: "EMAIL",
        name: "email"
    },
    {
        label: "PHONE",
        name: "phone"
    },
    {
        label: "LOCATION",
        name: "address"
    }
];

export const pagesColumn = [
    {
        label: "PAGE NAME",
        name: "title"
    },
    {
        label: "LAST UPDATED",
        name: "last_updated"
    }
];

export const blockWordsColumn = [
    {
        label: "USER NAME",
        name: "full_name"
    },
    {
        label: "EMAIL",
        name: "email"
    },
    {
        label: "PHONE",
        name: "phone"
    },
    {
        label: "LOCATION",
        name: "address"
    }
]

export const singlePendingApprovalUser = [
    {
        label: "Name",
        name: "full_name"
    },
    {
        label: "Company Name",
        name: "company_name"
    },
    {
        label: "Username",
        name: "username"
    },
    {
        label: "Email",
        name: "email"
    },
    {
        label: "Phone",
        name: "phone"
    },
    {
        label: "Address",
        name: "address"
    }
];

export const userAccountColumns = [
    {
        label: "USER NAME",
        name: "full_name",
        navigate: true,
        path: "/account_details"
    },
    {
        label: "EMAIL",
        name: "email",
        navigate: true,
        path: "/account_details"
    },
    {
        label: "PHONE",
        name: "phone"
    },
    {
        label: "LOCATION",
        name: "address"
    }
];

export const deleteOption = ["Select a reason", "Select a reason", "Select a reason", "Select a reason", "Select a reason"];
export const issueOption = ["Select a reason", "Select a reason", "Select a reason", "Select a reason", "Select a reason"];
export const refundOption = ["Select a reason", "Select a reason", "Select a reason", "Select a reason", "Select a reason"];

export const editSubscriptonPrice = ["$26.95", "$26.95", "$26.95"];
export const editSubscriptonDiscount = ["0%", "1%", "2%"];

export const privateAccountBasicDetailsColumn = [
    {
        label: "Name",
        name: "full_name"
    },
    {
        label: "Username",
        name: "username"
    },
    {
        label: "Email",
        name: "email"
    },
    {
        label: "Phone",
        name: "phone"
    },
    {
        label: "Address",
        name: "address"
    },
    {
        label: "Current plan",
        name: "current_plan"
    }
];

export const dealerAccountBasicDetailsColumn = [
    {
        label: "Name",
        name: "full_name"
    },
    {
        label: "Username",
        name: "username"
    },
    {
        label: "Email",
        name: "email"
    },
    {
        label: "Phone",
        name: "phone"
    },
    {
        label: "City",
        name: "city"
    },
    {
        label: "Address",
        name: "address"
    },
    {
        label: "Current plan",
        name: "current_plan"
    },
    {
        label: "About",
        name: "about",
        style: "col-12 mb-4"
    }
];

export const advertsPrivateAccountColumn = [
    {
        label: "DATE POSTED",
        name: "date"
    },
    {
        label: "IMPRESSION",
        name: "imppression"
    },
    {
        label: "STATUS",
        name: "status"
    }
]

export const advertsDealerAccountColumn = [
    {
        label: "DATE POSTED",
        name: "date"
    },
    {
        label: "IMPRESSION",
        name: "imppression"
    },
    {
        label: "STATUS",
        name: "status"
    }
]

export const transactionDealerAccountColumn = [
    {
        label: "ID",
        name: "id"
    },
    {
        label: "DATE",
        name: "date"
    },
    {
        label: "ITEM",
        name: "item",
    },
    {
        label: "AMOUNT",
        name: "amount"
    },
    {
        label: "STATUS",
        name: "status"
    }
]

export const transactionPrivateAccountColumn = [
    {
        label: "ID",
        name: "id"
    },
    {
        label: "DATE",
        name: "date"
    },
    {
        label: "ITEM",
        name: "item",
    },
    {
        label: "AMOUNT",
        name: "amount"
    },
    {
        label: "STATUS",
        name: "status"
    }
];

export const bannerImagesColumn = [
    {
        label: "PAGE NAME",
        name: "page_name"
    },
    {
        label: "LAST UPDATED",
        name: "last_updated"
    },
    {
        label: "CURRENT BANNER",
        name: "current_banner",
        type: 'image',
        style: "w-100"
    }
]