import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function BoostrapModel({show, heading, component, onHide, size, style}) {
  return (
    <>
      <Modal show={show} size={size} onHide={onHide} className={'warning'} centered>      
        <Modal.Header closeButton>
          <Modal.Title>{heading}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{component}</Modal.Body>
      </Modal>
    </>
  );
}

export default BoostrapModel;