import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import HeadingWithDatePicker from "../../Tools/DatepickerWithHeading";
import BoxItem from "../../Tools/BoxList";
import TradeBox from "../../Tools/TradeBox";
import { overviewFun } from "../../../Reducer/dashboard/dashboardSlice";

const Overview = () => {
  const overviewData = useSelector(state => state.dashboard?.overview);
  const error = useSelector(state => state.dashboard.error);
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(overviewFun({ time: "This weeks" }));
  }, []);
  const onsubmit = (data) => {
    const formData = new FormData();
    formData.append('time', formData);
    dispatch(overviewFun(formData));
  }
  if (error && error.status) {
    switch (error.status) {
      case 403:
        toast.error(error.data.message);
        break;
      default:
        toast.error("Something went wrong");
        break;
    }
  }

  return (<TradeBox parentStyle={"set1"} childStyle={"trade"} heading={"Overview"} onClick={onsubmit} component={<BoxItem parentStyle={'col-sm-2'} childStyle={'st'} data={overviewData} />} />
  )
}
export default Overview;