import React from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import swal from "sweetalert";
import { toast } from 'react-toastify';
import { pendingApprovalActionFun } from "../../../services/api";
import { getAllUserFun } from "../../../Reducer/user/userSlice";
import 'react-toastify/dist/ReactToastify.css';
import BoostrapModel from "../../Tools/BoostrapModel";
import KycModel from "./model";
import KycBox from '../../Tools/KycBox'
const KycDocument = ({ title1, title2, image, buttonName }) => {
    const [show, setShow] = React.useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleSubmit = async (label) => {
        try {
            const formData = new FormData();
            formData.append('action', label);
            const response = await pendingApprovalActionFun(formData);
            if (response.status) {
                dispatch(getAllUserFun());
                // toast('Successfully Updated');
                swal('success', response.message, 'success');
                navigate('/approval_que')
            }
        }
        catch (e) {
            toast.error(e.response.data.error);
        }


    }
    return (<div class="row">
         <KycBox
            parentStyle={"col-lg-6 col-xl-5 col-xxl-4 mb-4"}
            childStyle={"Documents"}
            title1={"KYC documents"}
            title2={"Identify card"}
            onClick={() => setShow(!show)}
            image={"../assets/images/eye2.png"} />
        <div class="col-sm-12 mt-4 text-end">
            <a class="btn" onClick={() => handleSubmit('Reject')}>Reject</a>
            <a class="btn approve" onClick={() => handleSubmit('Approve')}>Approve</a>
        </div>
        <BoostrapModel show={show} heading={"Kyc for likeston toyota"} style={"warning warningKyc"} component={<KycModel />} onHide={() => setShow(!show)} />
    </div>)
}

export default KycDocument;