import React from "react";
import { Link, NavLink } from "react-router-dom";
import { sidebarItemMenu } from "../../../data/data";
import ListItem from "../../Tools/ListItem";
import $ from "jquery";
const Sidebar = ({component}) => {
   const [activeTab, setActiveTab] = React.useState(false);
   console.log("activeTab", activeTab);
   return (<div class="container h-100" >
      <section class="dashnboard ">
         <div class="desh">
            <div class="hom d-block d-md-none">
               <span class="mnu" onClick={()=>$('.sidebar').addClass('main')}>
                  <i class="fa-solid fa-bars"></i>
               </span>
               <a class="logo" href="#"><img src="assets/images/Logo.png" /></a>
            </div>
            <span><h2>{activeTab ? activeTab : "Dashboard"}</h2></span>
            <span class="imgus"><img class="h-100 w-100" src="assets/images/us.png" alt="img" /></span>
            <div class="sidebar">
               <div class="sid1">
                  <span class="mnu d-block d-md-none" onClick={()=>$('.sidebar').removeClass('main')}><i class="fa-solid fa-xmark"></i></span>
                  <NavLink className={"logo"} to={"/"}><img src="assets/images/Logo.png" /></NavLink>
                  <ul>
                     {sidebarItemMenu.map((item)=>{
                        return <ListItem innerhtmlType={"html"} listClick={() => setActiveTab(item.title)} style={activeTab == item.title && "active"} value={<Link to={item.path}>{activeTab != item.title && <img src={item.imageOne} alt="icon" />}{activeTab == item.title && <img src={item.imageSecond} class="img2" alt="icon2" />}{item.title}</Link>} />
                     })}
                  </ul>
               </div>
            </div>
            {component}
         </div>
      </section>
   </div>)
}

export default Sidebar;