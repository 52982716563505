import getAlluser from './users.json';
import metricsFunApi from './metrics.json';
import keyStatsFunApi from './keyStats.json';
import overviewFunApi from './overview.json';
import pagesFunApi from './pages.json';
import blockWordsFunApi from './block_words.json';
import singleUserPendingApprovalApi from './singleUserDetails.json'
import editPageApi from './editpage.json';
import pendingApprovalActionApi from './actionPendingAprroval.json';
import pendingAprrovalDeleteApi from './deletePendingAprroval.json';
import deletePageAPi from './deletePages.json';
import userAccountListApi from './user_accounts.json';
import reportApi from './report.json';
import pricingApi from './pricing.json';
import editSubscriptionApi from './editSubscription.json';
import addSubscriptionApi from './addSubscription.json';
import accountDetailsApi from './accountDetails.json';
import trasncationDeleteApi from './transcationDelete.json';
import getUsersApi from './sendNotificationUser.json';
import getBannerImageApi from './bannerImages.json';
import graphApi from './graph.json';

export const getUserApi = (time) => {
      const response = getAlluser;
      return response;
}

export const getMetricsApi = async (time) => {
      try {
            const response = metricsFunApi;
            return response;
      }
      catch (e) {
            console.log("error", e);
      }
}

export const getKeyStatsApi = async (time) => {
      try {
            const response = keyStatsFunApi;
            return response;
      }
      catch (e) {
            console.log("error", e);
      }
}

export const getOverviewApi = async (time) => {
      try {
            const response = overviewFunApi;
            return response;
      }
      catch (e) {
            console.log("error", e);
      }
}

export const getPagesApi = async () => {
      try {
            const response = pagesFunApi;
            return response;
      }
      catch (e) {
            console.log("error", e);
      }
}

export const getBlockWordApi = async (time) => {
      try {
            const response = blockWordsFunApi;
            return response;
      }
      catch (e) {
            console.log("error", e);
      }
}

export const getSingleUserPendingApprovalFun = async (body) => {
      try {
            const response = singleUserPendingApprovalApi;
            return response;
      }
      catch (e) {
            console.log("error", e);
      }
}

export const editPageApiFun = async (body) => {
      try {
            const response = editPageApi;
            return response;
      }
      catch (e) {
            console.log("error", e)
      }
}

export const pendingApprovalActionFun = async (body) => {
      try {
            const response = await pendingApprovalActionApi;
            return response;
      }
      catch (e) {
            console.log("error", e);
      }
}
export const deletePendingAprrovalFun = async (body) => {
      try {
            const response = await pendingAprrovalDeleteApi;
            return response;
      }
      catch (e) {
            console.log("error", e)
      }
}

export const deletePagesFun = async (body) => {
      try {
            const response = await deletePageAPi;
            return response;
      }
      catch (e) {
            console.log("error", e);
      }
}

export const userAccountListFun = async (body) => {
      try {
            const response = await userAccountListApi;
            return response
      }
      catch (e) {
            console.log("error", e);
      }

}


export const reportFun = async (body) => {
      try {
            const response = await reportApi;
            return response;
      }
      catch (e) {
            console.log("error", e)
      }
}

export const getPricingFun = async () => {
      try {
            const response = await pricingApi;
            return response;
      }
      catch (e) {
            console.log("error", e);
      }
}

export const editSubscriptionFun = async (body) => {
      try {
            const response = await editSubscriptionApi;
            return response;
      }
      catch (e) {
            console.log("error", e);
      }
}

export const addSubscriptionFun = async (body) => {
      try {
            const response = await addSubscriptionApi;
            return response;
      }
      catch (e) {
            console.log("error", e);
      }
}

export const getAccountDetailsFun = async (body) => {
      try {
            const response = await accountDetailsApi;
            return response;
      }
      catch (e) {
            console.log("error", e);
      }
}

export const trasncationDeleteFun = async (body) => {
      try {
            const response = await trasncationDeleteApi;
            return response;
      }
      catch (e) {
            console.log("error", e);
      }
}

export const getUsersFun = async () => {
      try {
            const response = getUsersApi;
            return response
      }
      catch (e) {
            console.log("error", e)
      }
}

export const getBannerImageFun = async () => {
      try {
            const response = getBannerImageApi;
            return response;
      }
      catch (e) {
            console.log("error", e);
      }
}
export const graphFun = async (time) => {
      try {
            const response = graphApi;
            return response;
      }
      catch (e) {
            console.log("error", e);
      }
}