import React from 'react';
import swal from 'sweetalert';
import { useForm } from 'react-hook-form';
import FormGroupInput from "../../Tools/FormGroupInput";
import FormGroupOption from "../../Tools/FormGroupOption";
import { editSubscriptonDiscount, editSubscriptonPrice } from "../../../data/data";
import FormGroupLabelInput from "../../Tools/FormGroupLabelInput";
import Button from '../../Tools/Button';
import { editSubscriptionFun } from '../../../services/api';

const EditForm = ({ onHide, id }) => {
    const { register, handleSubmit } = useForm();
    const [inputList, setInputList] = React.useState([""]);
    const handleAddInput = () => {
        const newInputList = [...inputList, ""];
        setInputList(newInputList);
    };
    const onSubmit = async (data) => {
        try{
            const response = await editSubscriptionFun(data);
            if(response.status)
            {
                swal('success', response.message, 'success');
                onHide();
            }
        }
        catch(error)
        {
            console.log("error", error);
        }
    }
    return (<div className="row"><form onSubmit={handleSubmit(onSubmit)}><div class="col-sm-12">
        <input type='hidden' {...register('id')} value={id} />
        <FormGroupLabelInput
            label={"Ad name"}
            parentStyle={"form-group"}
            childStyle={"form-control"}
            event={register}
            name={"name"}
            placeholder={"Basic 2 Weeks Ad"}
        />
    </div>
        <FormGroupOption
            parentStyle={"col-sm-6"}
            childStyle={"form-group"}        
            selectStyle={"form-control"}
            label={"Price"}
            name={"price"}
            event={register}
            options={editSubscriptonPrice}
        />
        <FormGroupOption
            parentStyle={"col-sm-6"}
            childStyle={"form-group"}
            selectStyle={"form-control"}
            label={"Discount"}
            name={"discount"}
            event={register}
            options={editSubscriptonDiscount}
        />
        <div class="col-sm-12">
            <h6>{"Features"}</h6>
            {inputList.map((input, index) => (
                <FormGroupInput
                    inType="text"
                    name={`feature[${index}]`}
                    event={register}
                    parentStyle={"form-control"}
                    ChildStyle={"form-control"}
                    placeholder={"Features I go here"}
                />
            ))}
            <div class="col-sm-12">
                <span class="upFeature" onClick={handleAddInput}>{"+ Add new feature"}</span>
            </div>
        </div>
        <div class="modal-footer">
            <Button type={"button"} style={"btn btn-secondary"} onClick={onHide} title={"Close"} />
            <Button type={"submit"} style={"btn btn-primary"} title={"Update plan"} />
        </div>
    </form></div>)
}

export default EditForm;