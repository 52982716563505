const FormGroupOption = ({label, parentStyle, childStyle, selectStyle, options, event, name}) => {
    return (  <div class={parentStyle}>
    <div class={childStyle}>
        <label>{label}</label>
        <select class={selectStyle} {...event(name)}>
            {options.length > 0 && options.length > 0 && options.map((item)=>{
               return <option value={item}>{item}</option>
            })}
        </select>
    </div>
</div>)
}
export default FormGroupOption