const KycModel = ({ heading }) => {
    return (
        <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">

            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img src="../assets/images/docsingle.png" class="w-100" alt="..." />
                </div>
                <div class="carousel-item">
                    <img src="../assets/images/docsingle.png" class="w-100" alt="..." />
                </div>
                <div class="carousel-item">
                    <img src="../assets/images/docsingle.png" class="w-100" alt="..." />
                </div>
            </div>
            <ul class="carousel-indicators">
                <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"> <img src="../assets/images/docsingle.png" alt="..." /></li>
                <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"><img src="../assets/images/docsingle.png" alt="..." /></li>
                <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"><img src="../assets/images/docsingle.png" alt="..." /></li>
            </ul>
        </div>
    )
}
export default KycModel;