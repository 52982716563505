import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUserApi } from '../../services/api';

export const getAllUserFun = createAsyncThunk('/getusers', async (formData, { rejectWithValue }) => {
  try {
    const response =  getUserApi();
    console.log("response", response);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

const userSlice = createSlice({
  name: 'user',
  initialState: {
    submitting: false,
    error: null,
    success: false,
    language:'',
    user:[],
    token:""
  },
  reducers: {
  },
  extraReducers: builder => {
    builder
      .addCase(getAllUserFun.pending, state => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getAllUserFun.fulfilled, (state,action) => {
        state.submitting = false;
        state.success=true;
        state.user=action.payload;
      })
      .addCase(getAllUserFun.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload.error;
        state.success = false;
      })
  },
});
export default userSlice.reducer;

