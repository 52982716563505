import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { DashboardPage, ApprovalQue, SendNotification, PagesPage, BannerImages, BlockWords, AccountDetails, SinglePendingApprovalUser, EditPage, UserAccount, Pricing } from '../Components';
const RouteWebPage = () => { 
    return (
        <Routes>
            <Route exact path='/' element={<DashboardPage />} />
            <Route exact path='/approval_que' element={<ApprovalQue />}  />
            <Route exact path='/pages' element={<PagesPage />} />
            <Route exact path='/block_words' element={<BlockWords />} />
            <Route exact path='/reveiw_approval_que/:id' element={<SinglePendingApprovalUser />} />
            <Route exact path='/edit_page/:id' element={<EditPage />}  />
            <Route exact path='/user_accounts' element={<UserAccount />} />
            <Route exact path='/account_details/:id' element={<AccountDetails />} />
            <Route exact path='/send_notifications' element={<SendNotification />}  />
            <Route exact path='/send_email' element={<h3>Page Not Found</h3>} />
            <Route exact path='/pricing_controls' element={<Pricing />}  />
            <Route exact path='/banner_images' element={<BannerImages />} />
        </Routes>
    )
}

export default RouteWebPage;