import SubscriptionBox from "../../Tools/SubscriptionBox";
const Subscription = ({ data, onClick }) => {
    const styles = {
        basic: {},
        standard: {},
        premium: { background: "#4C7EE8" },
        ultimate: { background: "#FCBC00" }
    }
    return (<div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="Trade" role="tabpanel" aria-labelledby="Trade-tab">
            <div class="row">
                {data.length > 0 && data.map((item) => {
                    const itemStyle = styles[item.title.toLowerCase()];
                    console.log("itemStyle", itemStyle);
                    return <SubscriptionBox
                        parentSTyle={"col-md-6 col-xxl-3 mb-3"}
                        childStyle={"set"}
                        id={item.id}
                        style={itemStyle}
                        title={item.title}
                        time={item.time}
                        pricing={item.price}
                        benefits={item.benefit}
                        onClick={() => onClick(item.id)}
                    />
                })}
            </div>
        </div>
    </div>)
}

export default Subscription;