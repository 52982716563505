import React from "react";
import { useSelector, useDispatch } from "react-redux";
import BasicTable from "../../Tools/Table";
import { blockWordsColumn } from "../../../data/data";
import TopbarWithDateandSearch from '../../Tools/TopbarWithDateandSearch';
import { BlockWordFun } from "../../../Reducer/blockWords/blockWordsSlice"

const BlockWords = () => {
    const rows = useSelector(state => state.blockWords?.blockWords)
    const dispatch = useDispatch();
    React.useEffect(()=>{
      dispatch(BlockWordFun({time: "This weeks"}))
    }, [dispatch])
    const data = rows && rows.map((item) => {
        return {
            ...item,
            full_name : item.full_name.English,
        }
    });
    const searchBar = (data)  => {
        console.log("data1", data)
    }
    const onsubmit = (data) => {
        let formData = new FormData();
        formData.append('time', data);
      dispatch(BlockWordFun(formData))
    }
   return(<div class="overview block_words mb-0">
<TopbarWithDateandSearch style={"trade"} heading={"Block Words"} searchOnClick={searchBar} dateOnclick={onsubmit} />
<BasicTable columns={blockWordsColumn} rows={data ? data : []}  />
   </div>)
}
export default BlockWords;