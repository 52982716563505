import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { toast } from 'react-toastify';
import swal from "sweetalert";
import { getAllUserFun } from "../../../Reducer/user/userSlice";
import HeadingWithDatePicker from "../../Tools/DatepickerWithHeading";
import { aprrovalUserColumn } from "../../../data/data";
import BasicTable from "../../Tools/Table";
import { singlePendingApprovalFun } from "../../../Reducer/user/singlePendingApprovalSlice";
import { deletePendingAprrovalFun } from "../../../services/api";
import SingleBoxItem from "../../Tools/SingleBoxItem";
import { fetchDelete, fetchView } from "../../Functions/DataTable";

const ApprovalQue = () => {
    const row = useSelector((state) => state.user?.user?.pending_trade_account_approvals);
    const pendingApprovals = useSelector(state => state.user?.user?.pending_approvals);
    const error = useSelector(state => state.user.error);
    const [action,setAction]=React.useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(getAllUserFun({ time: "This Weeks" }));
        fetchActions();
    }, []);

    console.log("row", row);
    const fetchActions=async()=>{
        const edit= fetchView('Edit',singlePendingApprovalFun,'reveiw_approval_que',dispatch,navigate,'', 'view');
        const viewNoraml= fetchView('View',singlePendingApprovalFun,'reveiw_approval_que',dispatch,navigate,'', 'view');
        const deleted= fetchDelete('Delete',deletePendingAprrovalFun,dispatch,'',getAllUserFun);
        setAction([viewNoraml,edit,deleted]);
    }

    const view = {
        label: "View",
        image: "eye2.png",
        onclick: (id) => {
           console.log("viewId", id);
        },
        path: "/reveiw_approval_que"
    }
    const onsubmit = (data) => {
        const formData = new FormData();
        formData.append('time', data);
        dispatch(getAllUserFun(formData));
    }

    if (error && error.status) {
        switch (error.status) {
            case 403:
                toast.error(error.data.message);
                break;
            default:
                toast.error("Something went wrong");
                break;
        }
    }
    const data = row && row.map((item) => {
        return {
            ...item,
            full_name: item.full_name.English,
            registration_date: moment.unix(item.registration_date).format('DD/MMM/YYYY')
        }
    });
    return (<div class="overview">
        <h3 class="trade"><HeadingWithDatePicker heading={"Pending trade account approvals"} event={{ onclick: onsubmit }} /></h3>
        <BasicTable actionType={"dropdown"} columns={aprrovalUserColumn ? aprrovalUserColumn : []} rows={data ? data : []} actions={action} view={view} />
        <SingleBoxItem parentStyle={"indiv"} childStyle={"st"} heading={data && data.length} description={"Pending Aprrovals"} />
    </div>)
}
export default ApprovalQue;