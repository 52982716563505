import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import HeadingWithDatePicker from "../../Tools/DatepickerWithHeading";
import BoxItem from "../../Tools/BoxList";
import BoxImageTitle from "../../Tools/BoxImageTitle";
import { keyStatsFun } from "../../../Reducer/dashboard/dashboardSlice";
import SingleBoxWithImageTitle from '../../Tools/SingleBoxWithImagetitle'
const KeyStats = () => {
    const data = useSelector((state) => state.dashboard?.keyStats);
    const error = useSelector(state => state.dashboard.error);
    console.log("data", data)
    const dispatch = useDispatch();
    React.useEffect(()=>{
        dispatch(keyStatsFun({time: "This week"}));
    },[dispatch]);
    const onsubmit = (data) => {
        const formData = new FormData();
        formData.append('time', data);
        dispatch(keyStatsFun(formData));
    }
    if(error && error.status)
    {
      switch (error.status) {
        case 403:
            toast.error(error.data.message);
          break;
        default:
            toast.error("Something went wrong");
          break;
      }
    }
    return (<div class="set1">
        <h3 class="trade"><HeadingWithDatePicker heading={"Key stats"} event={{ onclick: onsubmit }} /></h3>
        <BoxItem parentStyle={"col-sm-2"} childStyle={'st'} data={data && data.trade_stats ? data.trade_stats : []} heading={"Trade Users Postign Ads"} />
        <BoxImageTitle parentStyle={"row mt-3"} childStyle={"col-sm-3"} data={data && data.trade_more_detail ? data.trade_more_detail : []} />
        <BoxItem parentStyle={"col-sm-2"} childStyle={'st'}  data={data && data.indiviual_stats ? data.indiviual_stats : []} heading={"Individual Users Postign Ads"} />
        <div class="row">
        <SingleBoxWithImageTitle parentStyle={"col-lg-5"} childStyle={"carsold"} image={"assets/images/dash_img/car (1) 1.png"} title={"527 Cars sold so far (Trade  &  individual users"} />
        </div>
    </div>)
}
export default KeyStats;