import React from "react";
import {useForm} from 'react-hook-form';
import Button from "../Button";
import FormInput from "../FormInput";
const ReasonModel = ({ buttonTitle, label, option, type, handleSubmitEvent, id, onHide }) => {
const {register, handleSubmit} = useForm();
return (<form onSubmit={handleSubmit(handleSubmitEvent)}>
        <label>{`Reason for ${label}?`}</label>
        <FormInput intype={"hidden"} value={id} name={"id"} />
        {type == "note" && <textarea className={"form-control"} name={"note"} {...register('note')} placeholder={""}>{"Note for this Transcation"}</textarea>}
        {type == "selection" && <select class="form-control" name="reason" {...register('reason')}>
            {option.map((item) => {
                return <option value={item}>{item}</option>
            })}
        </select>}

        <div class="modal-footer">
            <Button type={"button"} style={"btn btn-primary"} title={"Close"} onClick={onHide} />
            <Button type={"submit"} style={"btn btn-secondary"} title={buttonTitle} />
        </div>
    </form>
    )
}
export default ReasonModel;