import SearchBar from '../SearchBar';
import HeadingWithDatePicker from '../DatepickerWithHeading';
const TopbarWithDateandSearch = ({ style, heading, searchOnClick, dateOnclick }) => {
    return (<h3 class={style}>{heading}<ul><li>
        <SearchBar event={{ onclick: searchOnClick }} />
    </li>
        <li>
            <HeadingWithDatePicker event={{ onclick: dateOnclick }} />
        </li>
    </ul></h3>)
}

export default TopbarWithDateandSearch;