import React, { Component } from "react";
import swal from "sweetalert";
import { useSelector, useDispatch } from "react-redux";
import BasicTable from "../../Tools/Table";
import TopbarWithDateandSearch from "../../Tools/TopbarWithDateandSearch";
import { userAccountList } from "../../../Reducer/userAccount/userAccountSlice";
import { userAccountColumns } from "../../../data/data";
import ReasonModel from "../../Tools/ReasonModel";
import BoostrapModel from "../../Tools/BoostrapModel";
import { deleteOption, issueOption, refundOption } from "../../../data/data";
import { reportFun } from "../../../services/api";
import { set } from "react-hook-form";

const UserAccount = () => {
    const rows = useSelector(state => state.userAccountList?.userAccountList);
    const [show, setShow] = React.useState(false);
    const [state, setState] = React.useState({
        id: '',
        label: '',
        heading: "",
        buttonTitle: '',
        option: [],
        type: ''
    })
    console.log("rows", rows)
    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(userAccountList({ time: "This weeks" }));
    }, [dispatch])
    const searchBar = (data) => {
        console.log("data", data);
    }
    const dateFilter = (time) => {
        const body = {
            time
        }
        dispatch(userAccountList)
    }
    const data = rows && rows.map((item) => {
        return {
            ...item,
            full_name: item.full_name.English
        }
    });
    const handleSubmit = async (data) => {
        const body = {
            data
        }
        try {
            const response = await reportFun(body);
            if (response.status) {
                setShow(!show);
                swal('success', response.message, 'success');
            }
        }
        catch (e) {
            console.log("error", e);
        }
    }

    const actions = [
        {
            label: "Issue Warning ?",
            onclick: (id) => {
                setState({
                    id: id,
                    title: "Issue Warning",
                    heading: "Issue Warning ?",
                    buttonTitle: "Issue Warning",
                    option: issueOption,
                    type: "selection"
                })
                setShow(!show)
            }
        },
        {
            label: "Delete Advert ?",
            onclick: (id) => {
                console.log("id", id)
                setState({
                    id: id,
                    heading: "Delete Advert ?",
                    title: "Delete Advert",
                    buttonTitle: "Delete",
                    option: deleteOption,
                    type: "selection"
                })
                setShow(!show)
            }

        },
        {
            label: "Refund for Transcation",
            onclick: (id) => {
                setState({
                    id: id,
                    title: "Refund for Transcation",
                    heading: "Refund for Transcation",
                    buttonTitle: "Refund for Transcation",
                    option: refundOption,
                    type: "selection"
                })
                setShow(!show)
            }
        },
        {
            label: "AddNote",
            onclick: (id) => {
                setState({
                    id: id,
                    title: "Add Note",
                    heading: "Add Note",
                    buttonTitle: "Delete",
                    option: [],
                    type: "note"
                })
                setShow(!show)
            }
        }

    ]

    return (<div class="overview">
        <TopbarWithDateandSearch heading={"Overview"} searchOnClick={searchBar} dateOnclick={dateFilter} />
        <BasicTable rows={data} columns={userAccountColumns} actionType={"dropdown"} actions={actions} />
        <BoostrapModel show={show} heading={state.heading} onHide={() => setShow(!show)} component={<ReasonModel
            id={state.id}
            label={state.title
            } buttonTitle={state.buttonTitle}
            option={state.option}
            type={state.type}
            handleSubmitEvent={handleSubmit}
            onHide={() => setShow(!show)} />} />
    </div>)
}
export default UserAccount;