import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Sidebar from './Components/Layouts/Sidebar';
import RouteWebPage from './routes';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="App">
       <BrowserRouter>
          <Sidebar component={<RouteWebPage />} />
            {/* <Route exact path="/Details" element={<AuthRoute element={<BasicDetail />} />}/> */}
      </BrowserRouter>
    </div>
  );
}

export default App;
